import React, { Component } from 'react'
import { graphql } from 'gatsby'
// import PostIcons from '../components/PostIcons'
import Layout from '../layouts'
import Banner from '../components/banner'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'

import {
  projectIsTrageberatung,
  projectIsFamillienBegleitung,
} from '../determine-project'

class PageTemplate extends Component {
  render() {
    let title = 'Stoffwindelberatung in Bielefeld – Julia Rose'
    if (projectIsTrageberatung) {
      title = 'Trageberatung in Bielefeld – Julia Rose'
    }
    if (projectIsFamillienBegleitung) {
      title = 'Familienbegleitung in Bielefeld – Julia Rose'
    }

    const homePage = this.props.data.wordpressPage
    const siteMetaData = this.props.data.allWordpressSiteMetadata.edges[0].node

    return (
      <Layout>
        <Helmet title={title} />
        {siteMetaData.description && (
          <Helmet>
            <meta name="description" content={siteMetaData.description} />
          </Helmet>
        )}

        <div className="post single page type-page status-publish hentry">
          {homePage.featured_media && homePage.featured_media.localFile && (
            <div className="featured-media">
              <Img
                fluid={homePage.featured_media.localFile.childImageSharp.fluid}
              />
            </div>
          )}
          <div className="post-header">
            <h1
              dangerouslySetInnerHTML={{ __html: homePage.title }}
              className="post-title"
            />
          </div>
          {/* <PostIcons node={currentPage} css={{ marginBottom: rhythm(1 / 2) }} /> */}
          <article
            dangerouslySetInnerHTML={{ __html: homePage.content }}
            className="post-content"
          />
        </div>
        <Banner homePage={homePage}/>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query {
    wordpressPage(slug: { eq: "startseite" }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        banner
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 640) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressSiteMetadata {
      edges {
        node {
          id
          description
          url
          name
        }
      }
    }
  }
`
